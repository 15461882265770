// src/App.tsx
import React from 'react';
import MqttButton from './components/mqtt-button/mqtt-button';

const App: React.FC = () => {
  return (
    <MqttButton />
  );
};

export default App;
