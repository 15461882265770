import React, { useState } from 'react';
import mqtt from 'mqtt';

const MqttButton = () => {
  const [mqttClient, setMqttClient] = useState<mqtt.MqttClient | null>(null);
  const [state, setState] = useState<null | 'connecting' | 'connected' | 'disconnect' | 'reconnect' | 'offline'>(null);
  const [error, setError] = useState<null | any>(null);

  const connectToBroker = () => {
    setState('connecting');
    // Replace the following values with your MQTT broker information
    const brokerUrl = 'mqtts://danielelidio.ddns.net';

    const client = mqtt.connect(brokerUrl, {
      port: 8883,
      clean: true,
    });

    client.on('connect', () => {
      console.log('Connected to MQTT broker');
      setMqttClient(client);
      setState('connected');
    });

    client.on('disconnect', () => {
      setState('disconnect');
    });

    client.on('offline', () => {
      setState('offline');
    });

    client.on('reconnect', () => {
      setState('reconnect');
    });

    client.on('error', (error) => {
      setError(error);
    });
  };

  const sendMessage = () => {
    if (mqttClient) {
      const topic = 'home_automation/ventilador/cmnd/POWER';
      const message = 'Toggle';

      mqttClient.publish(topic, message, (err) => {
        if (err) {
          alert(err)
          console.error('Error publishing message:', err);
        } else {
          console.log('Message published successfully');
        }
      });
    } else {
      console.warn('Not connected to MQTT broker. Press the "Connect" button first.');
    }
  };

  return (
    <div>
      <button onClick={() => connectToBroker()}>Connect to MQTT Broker</button>
      <button onClick={sendMessage}>Send Message</button>
      <p>{state}</p>
      <p>{error}</p>
    </div>
  );
};

export default MqttButton;
